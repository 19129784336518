// import './bootstrap';
import './axios';
import '../css/app.css';

import { createApp, createSSRApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import vSelect from "vue-select";
import * as Sentry from "@sentry/vue";

// Vue.component("v-select", vSelect);

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {

        let app = createApp({ render: () => h(App, props) });

        Sentry.init({
            app,
            dsn: "https://05bcdc5fdebbbd8aff911f8d272285d3@o4507563472715776.ingest.us.sentry.io/4507563476451328",
            integrations: [
                // Sentry.browserTracingIntegration(),
                // Sentry.replayIntegration(),
                // Sentry.captureConsoleIntegration(),
            ],
            // attachStacktrace: true,
            // Performance Monitoring
            // tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            // tracePropagationTargets: ["localhost", "73.109.38.2"],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            // debug: true,
            // trackComponents: true,
            release: "2024-09-23",
            // environment: "research"

        });

    app.use(plugin)
        .use(ZiggyVue, Ziggy)
        .component("v-select", vSelect)
        .mount(el);

        return app;
    },
    progress: {
        color: '#4B5563',
    },
});
